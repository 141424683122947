@charset "UTF-8";

@import "../mediaQuery";
@import "../variables";

.overlay {
  width: 100vw;
  height: $vhTop;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.offer-content {
  left: 50%;
  bottom: 50%;
  overflow: hidden;
  height: 100%;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  line-height: 1.6;
}

header {
  display: flex;
}

.lHeaderLogoGroup {
  display: flex;
  align-items: flex-end;
}

.lMainContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  bottom: 0;
}

.lMainWrap {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2vh;
  width: 100vw;
  position: relative;
}

.lMainClose {
  position: absolute;
  top: 3%;
  right: 3%;
}

.lMainClose img {
  width: 30px;
}

.lMainMission h2 {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 20px;
  font-weight: 700;
  color: #fd6b46;
  margin-bottom: clamp(12px, 5vw, 24px);
}

.lMainMission ul {
  display: flex;
  padding-bottom: 16px;
  gap: 8px;
  overflow: hidden;
  overflow-x: auto;
  justify-content: center;
}

.lMainMission ul::-webkit-scrollbar {
  display: none; /* Chrome/Safari用: スクロールバー非表示 */
}

.lMainMission ul li {
  flex: 0 0 65px;
}

.lMainMission img {
  border-radius: 8px;
}

.lMainMissionCoin {
  text-align: center;
  font-weight: 700;
  margin: clamp(2px, 1vw, 8px) 0 16px;
}

.lMainMissionCoin p {
  color: #fd6b46;
  font-size: 35px;
  font-style: normal;
}

.lMainMissionCoin p span {
  color: #333;
}

.lMainCase {
  background-color: #fff8f6;
  width: 100%;
  padding: 24px 16px;
}

.lMainCase h3 {
  font-size: min(5vw, 16px);
  text-align: center;
  color: #fd6b46;
  font-weight: 700;
  margin-bottom: 16px;
}

.lMainCase ul li {
  background-color: #fff;
  border-radius: 8px;
  padding: 14px 16px;
  display: flex;
  gap: 14px;
  margin-bottom: 8px;
}

.lMainCaseLeft {
  flex: 0 0 80px;
}

.lMainCaseLeft img {
  border-radius: 8px;
}

.lMainCaseRight .lMainCaseText {
  margin-bottom: 16px;
}

.lMainCaseRight .lMainCaseText h4 {
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-word; /* 英単語を強制的に折り返す */
  overflow-wrap: break-word;
}

.lMainCaseRight .lMainCaseText p {
  font-size: 10px;
  color: #8a8a8a;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-word; /* 英単語を強制的に折り返す */
  overflow-wrap: break-word;
}

.lMainCaseRight .lMainCaseCoin {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.lMainCaseCoinBefore {
  flex: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: line-through;
  opacity: 0.4;
  font-size: 12px;
}

.lMainCaseCoinBefore img {
  flex: 0 0;
  width: 12px;
}

.lMainCaseCoinAfter {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #fd6b46;
  font-size: 16px;
  font-weight: 700;
}

.lMainCaseCoinAfter img {
  flex: 0 0;
  width: 15px;
}

.lMainCaseItem {
  position: relative;
}

.lMainCaseLink {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.lMainMission h2 img {
  width: 25px;
}

/* 縦画面（ポートレート）時のみ width を 60vw にする */
@media (orientation: portrait) {
  .lMainCaseCoin {
    width: 60vw;
  }
}

/* 横幅が649px以上の場合：ほぼiPhone SE 横画面用の記述 */
@media (min-width: 649px) {

  .lMainWrap {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 100vw;
    height: 100vh;
    position: relative;
  }

  .lMainCase {
    background-color: #fff8f6;
    width: 100%;
    padding: 24px 16px;
    height: 100%;
  }
  
  .lMainMission ul {
    display: flex;
    padding-bottom: 16px;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
    justify-content: center;
  }

  .lMainMission h2 {
    margin-bottom: 8px;
    font-size: 18px;
  }

  .lMainMission ul li {
    flex: 0 0 50px;
  }

  .lMainMission .lMainMissionCoin {
    margin: 0 0 8px;
  }

  .lMainMission .lMainMissionCoin p {
    font-size: 28px;
  }

  .lMainMission .lMainMissionCoin p span {
    font-size: 16px;
  }

  .lMainCase ul {
    display: flex;
    gap: 8px;
  }

  .lMainCase ul li {
    width: 33.333333%;
    margin-bottom: 0;
    align-items: center;
  }

  .lMainCase ul li .lMainCaseLeft {
    flex: 0 0 56px;
  }

  .lMainCase ul li .lMainCaseRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .lMainCase ul li .lMainCaseRight .lMainCaseCoin {
    gap: 0;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
  }

  .lMainCase ul li .lMainCaseRight .lMainCaseText {
    margin-bottom: 4px;
  }
}

/* 横幅が840px以上の場合：iPhoneSEよりも大きい普通のスマホ横画面用の記述 */
@media (min-width: 840px) {

  .lMainWrap {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 100vw;
    height: 100vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .lMainMission ul {
    display: flex;
    padding-bottom: 16px;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
    justify-content: center;
  }

  .lMainMission h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .lMainMission .lMainMissionCoin p {
    font-size: 37px;
  }

  .lMainMission .lMainMissionCoin p span {
    font-size: 16px;
  }
}

/* iPad Pro と iPad Mini の **縦画面** に適用 */
@media (min-height: 1024px) and (orientation: portrait) {
  .lMainMission ul {
    display: flex;
    padding-bottom: 16px;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
    justify-content: center;
  }

  .lMainWrap {
    padding-top: 2vh;
    height: 75vh;
  }

  .lMainMission h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .lMainMission ul li {
    flex: 0 0 70px;
  }

  .lMainMission .lMainMissionCoin {
    margin: 8px 0 16px;
  }

  .lMainMission .lMainMissionCoin p {
    font-size: 32px;
  }

  .lMainMission .lMainMissionCoin p span {
    font-size: 20px;
  }

  .lMainCase ul {
    max-width: 500px;
    margin: auto;
    flex-direction: column;
  }

  .lMainCase ul li {
    width: 100%;
  }

  .lMainCase ul li .lMainCaseLeft {
    flex: 0 0 72px;
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .lMainContent {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* iPad Pro & iPad Mini の **横画面 (landscape)** 用の CSS */
@media (min-width: 1024px) and (orientation: landscape) {
  .lMainWrap {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 100vw;
    height: 100vh;
    position: relative;
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
  }

  .lMainMission h2 {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .lMainMission .lMainMissionCoin p {
    font-size: 37px;
  }

  .lMainMission .lMainMissionCoin p span {
    font-size: 16px;
  }
}

/* iPad Pro & iPad Mini の横画面を確実に適用 (画面の横縦比で判定) */
@media screen and (min-aspect-ratio: 4/3) {
  .lMainWrap {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 100vw;
    height: 100vh;
    position: relative;
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
  .lMainWrap {
    background-color: #fff;
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2vh;
    width: 100vw;
    height: 95vh;
    position: relative;
    transform: scaleY(0.85);
  }

  .lMainCase h3 {
    font-size: min(5vw, 16px);
    text-align: center;
    color: #fd6b46;
    font-weight: 700;
    margin-bottom: 16px;
    transform: scaleY(1.1);
  }

  .lMainCaseLeft {
    flex: 0 0 80px;
    transform: scaleX(0.95);
  }

  .lMainMission ul {
    display: flex;
    padding-bottom: 16px;
    gap: 0;
    overflow: hidden;
    overflow-x: auto;
    justify-content: center;
  }

  .lMainMission h2{
    transform: scaleX(0.85);
  }

  .lMainMission img{
    transform: scaleX(0.85);
    border-radius: 8px;
  }

  .lMainMissionCoin{
    transform: scaleX(0.85);
  }

  .lMainCase ul {
    transform: scaleX(0.9);
  }
}

//iPhoneSE第一世代 画面サイズ用
@media screen and (max-width: 321px) and (max-height: 569px) {
  .lMainCaseCoin {
    width: 50vw;
  }
}