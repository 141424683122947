@import "../mediaQuery";
@import "../variables";

.wrapper {
  margin-bottom: 6px;

  .link {
    text-decoration: none;

    .descriptionWrapper {
      display: flex;
      align-items: center;

      .description {
        padding-right: 5px;

        .title {
          font-family: "ヒラギノ角ゴシック";
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .offerTileContainer {
          display: flex;
          align-items: center;
        }

        .offerTileType {
          font-size: 11px;
          margin-right: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .point {
          font-family: "Helvetica";
          font-weight: bold;
          font-size: 15px;
          display: flex;
        }
      }

      .offerTileButton {
        display: flex;
        padding: 10px 14px;
        font-size: 12px;
      }
    }

    .pointUnit {
      font-size: 10px;
    }
  }

  .image {
    // 横750:縦390のアスペクト比で固定する
    aspect-ratio: 25/13;
    width: 100%;
  }

  .videoImage {
    height: 32px;
    margin-right: 5px;
  }

  .iconAndButton {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.surveyBunnarImage {
  width: 100%;
}
