@media screen and (orientation: portrait) {
  .wrapper {
    padding: 0.625rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .offer {
    width: 100%;
    display: flex;

    &_content {
      height: 5rem;
      width: calc(100% - 6.75rem);
      padding-left: 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      &_upper {
        display: flex;
        align-items: center;

        .info {
          width: calc(100% - 3.125rem);

          &_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .recommendIcon {
        width: 3.125rem;
        position: absolute;
        top: 0;
        right: 0;

        img {
          height: 100%;
          width: 100%;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }

      &_button {
        width: 100%;
        min-height: 2.18rem;
        padding: 3px;
      }

      &_tripMileButton {
        width: 100%;
        min-height: 2.18rem;
        padding: 3px;
        box-shadow: 0 0.3rem #4c3575;
        border-radius: 3rem;
      }
    }
  }
}

// 横画面用のスタイル
@media screen and (orientation: landscape) {
  .wrapper {
    padding: 0.625rem;
    border-bottom: 1px solid #e0e0e0;
  }

  .offer {
    width: 100%;
    display: flex;

    &_content {
      height: 5rem;
      width: calc(100% - 6.75rem);
      padding-left: 1rem;
      flex: 1;
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-bottom: 10px;
      justify-content: space-between;

      &_upper {
        order: 1;
        flex-grow: 1;
        max-width: calc(100% - 13rem); /* 全体からbuttonの幅を引く */
        display: flex;
        align-items: center;

        .info {
          width: 95%;

          &_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      // おすすめアイコンがある場合
      :has(.recommendIcon) > &_upper {
        max-width: calc(100% - 13rem - 5rem); /* 全体からbuttonとrecommendIconの合計幅を引く */
      }

      &_button {
        order: 3;
        width: 13rem;
        min-height: 2.18rem;
        padding: 3px;
      }

      &_tripMileButton {
        order: 3;
        width: 13rem;
        min-height: 2.18rem;
        padding: 3px;
        box-shadow: 0 0.3rem #4c3575;
        border-radius: 3rem;
      }

      .recommendIcon {
        order: 2;
        width: 5rem;
        height: auto;
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
          height: auto;
          vertical-align: middle;
          border-style: none;
          float: right;
        }
      }
    }
  }
}
